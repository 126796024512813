import type { IPictureElementList } from '../../atoms/PictureElementList'
import type { ISponsorBanner } from '../../molecules/SponsorBanner'
import type { ICarousel } from '../../molecules/Carousel'

import { useContext } from 'react'
import slugify from 'slugify'
import { CarouselArticleItem, ICarouselArticleItem } from '../../atoms/CarouselArticleItem'
import { Link } from '../../atoms/Link'
import { Logo } from '../../atoms/Logo'
import { Carousel } from '../../molecules/Carousel'
import { SponsorBanner } from '../../molecules/SponsorBanner'
import { ARTICLE_ICON_VIDEO } from '../../../constants/components'
import { TagCommanderContext } from '../../../contexts/tagcommander'
import { Button } from '../../atoms/Button'
import { SVGIcon } from '../../atoms/SVGIcon'
import { getContrastColor } from '../../../helpers/colors'
import { theme, mixins } from '../../../styles'
import { useViewport } from '../../../hook/useViewport'

export interface ICarouselHighlights extends ICarousel {
  sponsor?: ISponsorBanner
  title: string
  logo?: IPictureElementList
  link: string
  button?: {
    link: string
    text?: string
  }
  description?: string
  elementList: (Pick<ICarouselArticleItem, 'title' | 'link' | 'pictures' | 'hasMultiLive'> & {
    icon?: string
    format?: string
  })[]
  backgroundColor?: string
  format?: string
}

export function CarouselHighlights({
  title,
  logo,
  link,
  button,
  description,
  elementList,
  sponsor,
  backgroundColor,
  format,
}: ICarouselHighlights): JSX.Element {
  const { hit } = useContext(TagCommanderContext)
  const { isMobile } = useViewport()

  function handleOnClick(location = null) {
    hit(
      {
        screen_clickableElementName: `bloc_carousel-highlights_${
          location ? `${location}_` : ''
        }${slugify(title, {
          lower: true,
        })}`,
      },
      { isClickEvent: true },
    )
  }

  function hitCarouselButton() {
    hit(
      {
        screen_clickableElementName: `bloc_carousel-highlights_button-${slugify(button.text, {
          lower: true,
        })}`,
      },
      { isClickEvent: true },
    )
  }

  const isSquare = format === 'square'
  const isVertical = format === 'portrait'
  const textColor = backgroundColor ? getContrastColor(backgroundColor) : undefined
  const carouselId = `CarouselHighlights-${slugify(title ?? '', { remove: /[*+~.,()'"!?:@]/g })}`

  const handleMobileIndexChange = (index: number) => {
    const allElements = document.querySelectorAll(
      `#${carouselId} .Carousel .swiper-wrapper .ArticleCard__Content`,
    )
    const element = document.querySelector(
      `#${carouselId} .Carousel .swiper-wrapper .ArticleCard__Content-${index}`,
    )

    // making sure carousel ends animation before zooming in
    setTimeout(() => {
      allElements.forEach((element) =>
        element?.classList?.remove('ArticleCard__Carousel__Img__Animation'),
      )
      element?.classList?.add('ArticleCard__Carousel__Img__Animation')
    }, 300)
  }

  return (
    <>
      <div className="CarouselHighlights flex flex-column items-center justify-center">
        <Link url={link}>
          <div className="CarouselHighlights__Header flex items-center justify-between">
            <div className="CarouselHighlights__Header__Wording flex items-center">
              {logo ? <Logo logo={logo} onClick={() => handleOnClick('picto')} /> : null}
              <div
                className="CarouselHighlights__Header__Title"
                onClick={() => handleOnClick('titre')}
              >
                <span>{title}</span>
                <p>{description}</p>
              </div>
            </div>
            {link && (
              <div onClick={() => handleOnClick('fleche')}>
                <SVGIcon name="arrow" primaryColor={theme.cssVars.white} />
              </div>
            )}
          </div>
        </Link>

        {sponsor?.title && (
          <div
            className="CarouselHighlights__Content__SponsorBanner"
            onClick={() => handleOnClick('logo-sponso')}
          >
            <SponsorBanner
              title={sponsor.title}
              link={sponsor.link}
              pictures={sponsor.pictures}
              type={sponsor.type}
            />
          </div>
        )}

        <div className="CarouselHighlights__Carousel" id={carouselId}>
          <Carousel
            pagination={isVertical ? false : 'mobile'}
            isVertical={isVertical}
            withWhiteBackground={theme.cssVars.deepBlue === textColor}
            onIndexChange={isMobile ? handleMobileIndexChange : null}
          >
            {elementList.map((element, index) => (
              <CarouselArticleItem
                key={index}
                index={index}
                title={element?.title}
                link={element.link}
                pictures={element.pictures}
                isVideo={element?.icon === ARTICLE_ICON_VIDEO}
                isCard
                isCarousel
                isBlured={!!element?.title}
                hasMultiLive={element?.hasMultiLive}
                onClick={handleOnClick}
                ratio={isVertical ? '9/16' : isSquare ? '1/1' : ''}
              />
            ))}
          </Carousel>
        </div>
        {button?.link ? (
          <div className="CarouselHighlights__Button">
            <Button
              backgroundColor={theme.colors.white}
              textColor={theme.colors.deepBlue}
              href={button.link}
              onClick={hitCarouselButton}
            >
              <p className="CarouselHighlights__Button__Text">{button.text}</p>
              <SVGIcon name="arrow" />
            </Button>
          </div>
        ) : null}
      </div>
      <style jsx>{`
        .CarouselHighlights {
          background-color: ${backgroundColor ? backgroundColor : theme.cssVars.deepBlue};
        }
      `}</style>
      <style jsx>{`
        .CarouselHighlights__Header {
          color: ${textColor ? textColor : theme.cssVars.white};
        }
      `}</style>
      <style jsx>{`
        .CarouselHighlights__Carousel :global(.swiper-wrapper > *) {
          height: ${isSquare ? 'max(100%, 315px)' : isVertical ? 'inital' : '450px'};
          max-height: ${isSquare ? 'max(100%, 315px)' : isVertical ? '640px' : '450px'};
        }

        .CarouselHighlights__Carousel :global(.swiper-wrapper .ArticleCard__Content) {
          height: ${isSquare ? 'max(100%, 315px)' : isVertical ? '100%' : '450px'};
          max-height: ${isSquare ? 'max(100%, 315px)' : isVertical ? '640px' : '450px'};
        }

        @media ${mixins.mediaQuery.tablet} {
          .CarouselHighlights__Carousel :global(.swiper-wrapper > *) {
            height: ${isSquare ? 360 : isVertical ? 640 : 450}px;
          }

          .CarouselHighlights__Carousel :global(.swiper-wrapper .ArticleCard__Content) {
            height: ${isSquare ? 360 : isVertical ? 640 : 450}px;
          }
        }

        .CarouselHighlights {
          padding: 30px;
        }

        @media (max-width: ${theme.breakpoints.md}px) {
          .CarouselHighlights :global(.ArticleCard__Title__Text) {
            font-size: ${isVertical ? '16px' : undefined};
          }
        }

        @media ${mixins.mediaQuery.mobile} {
          .CarouselHighlights {
            padding: ${isVertical ? ' 30px 0 30px 30px' : '30px'};
          }
        }
      `}</style>
      <style jsx>{`
        .CarouselHighlights {
          width: 100%;
          margin-bottom: 50px;
          gap: 30px;
        }

        .CarouselHighlights :global(> a) {
          width: 100%;
        }

        .CarouselHighlights__Content__SponsorBanner {
          width: 100%;
          margin-bottom: ${theme.block.marginBottom}px;
        }

        .CarouselHighlights__Header {
          width: 100%;
          line-height: 23px;
        }

        .CarouselHighlights__Header__Wording :global(svg) {
          min-width: 48px;
          min-height: 48px;
        }

        .CarouselHighlights__Header__Wording :nth-child(2) {
          margin-left: 10px;
        }

        .CarouselHighlights__Header__Title {
          width: 100%;
          font-family: ${theme.fonts.overpass};
          font-size: 17px;
          font-weight: 700;
        }

        .CarouselHighlights__Header__Title :global(p) {
          font-size: 13px;
          font-weight: 300;
        }

        .CarouselHighlights__Carousel {
          position: relative;
          overflow-y: hidden;
          width: 100%;
          max-width: 100vw;
          display: flex;
          flex-wrap: nowrap;
        }

        .CarouselHighlights__Carousel :global(.Carousel) {
          width: 100%;
        }

        .CarouselHighlights__Button {
          padding: 0 30px;
          width: 100%;
        }

        .CarouselHighlights__Button__Text {
          margin: 2.5px 10px 0 0;
        }

        @media ${mixins.mediaQuery.tablet} {
          .CarouselHighlights {
            padding: 30px 0 30px 20px;
          }

          .CarouselHighlights__Header {
            padding-right: 20px;
          }

          .CarouselHighlights__Carousel :global(.Carousel) {
            width: initial;
          }

          .CarouselHighlights__Content__SponsorBanner {
            padding-right: 20px;
          }

          .CarouselHighlights__Button {
            max-width: 400px;
            margin-top: 30px;
          }
        }

        @media ${mixins.mediaQuery.desktop} {
          .CarouselHighlights {
            padding: 30px 0 30px 90px;
          }

          .CarouselHighlights__Header {
            padding-right: 90px;
          }

          .CarouselHighlights__Content__SponsorBanner {
            padding-right: 90px;
          }

          .CarouselHighlights__Carousel :global(.pagination-progress) {
            margin-left: -40px;
          }

          .CarouselHighlights__Content__SponsorBanner :global(.SponsorBanner) {
            border-radius: 5px;
          }
        }
      `}</style>
    </>
  )
}
